<template>
  <Layout tituloPagina="Sistema | Mi Servicio de Internet | Ajustes | General">
    <div class="row">
      <div class="col-lg-3">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              Logo principal del portal
            </h4>
          </div>
          <div class="card-body">
            <div class="dropzone text-center">
              <div class="dz-message needsclick cursor" v-if="!imagen_logo" @click="seleccionarLogo()">
                <div class="mb-0">
                  <i class="display-4 text-muted bx bx-cloud-upload"></i>
                </div>
                <h5>Haga clic para cargar el logo</h5>
              </div>
              <div class="marcoLogo" v-if="imagen_logo">
                <img
                  :src="imagen_logo"
                  class="img-fluid rounded cursor"
                  @click="visibleImagen = true"
                />
                <button
                  class="btnEliminarLogo btn btn-danger btn-sm btn-rounded"
                  title="Borrar logo"
                  @click="eliminarLogo(index)"
                >
                  <i class="mdi mdi-close-thick"></i>
                </button>
              </div>
            </div>
            <vue-easy-lightbox
              :visible="visibleImagen"
              :imgs="imagen_logo"
              @hide="visibleImagen = false"
            ></vue-easy-lightbox>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              Ajustes generales
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#" class="dropdown-item" onclick="return false"
                      @click="actualizar()" v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      Actualizar
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      Actualizar
                    </span>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#" onclick="return false;" @click="$router.go(-1)">
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <h6>
              Ajustes generales
              (<a href="https://miserviciodeinternet.com" target="_blank">
                https://miserviciodeinternet.com
              </a>)
            </h6>
            <div class="row">
              <div class="col-md-6">
                <label>Nombre de la empresa</label>
                <input
                  type="text"
                  placeholder="Nombre de la empresa"
                  name="nombre_empresa"
                  v-model="ajustes.nombre_empresa"
                  class="form-control"
                />
              </div>
              <div class="col-md-6">
                <label>Moneda</label>
                <select name="moneda" class="form-select" v-model="moneda.id_moneda">
                  <option :value="null">Seleccionar moneda</option>
                  <option
                    v-for="moneda in monedas"
                    :key="moneda.id"
                    :value="moneda.id"
                  >
                    ({{moneda.codigo}}) - {{moneda.nombre}}
                  </option>
                </select>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-md-6">
                <label>Prefijo numérico cliente</label>
                <input
                  type="text"
                  class="form-control"
                  title="El prefijo no puede ser mayor a 6 caracteres"
                  placeholder="PREF12"
                  name='prefnumcli'
                  v-model="ajustes.prefnumcli"
                  style="text-transform:uppercase;"
                  maxlength="6"
                />
              </div>
              <div class="col-md-6">
                <label>Código de pais para telefonos</label>
                <select class="form-select" v-model="ajustes.id_pais">
                  <option :value="null">Seleccionar</option>
                  <option
                    v-for="pais in paises"
                    :key="pais.id"
                    :value="pais.id"
                  >
                  ({{pais.prefijo_telefonico}}) - {{pais.nombre}}
                  </option>
                </select>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-md-6">
                <label>Agregar donde pagar</label>
                <div class="form-check form-switch form-switch-lg">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="dondePagar"
                    :checked="ajustes.donde_pagar"
                    @change="ajustes.donde_pagar = !ajustes.donde_pagar"
                  />
                  <label class="form-check-label" for="dondePagar">
                    {{ ajustes.donde_pagar ? 'Mostrar' : 'No mostrar' }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <div class="btn-group">
            <button class="btn btn-secondary" @click="$router.go(-1)">
              <i class="mdi mdi-chevron-left"></i>
              Atras
            </button>
            <button class="btn btn-success" @click="actualizar()" :disabled="bandera_spinner">
              <i
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              Actualizar
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import SistemaSrv from "@/services/SistemaSrv.js"
import VueEasyLightbox from "vue-easy-lightbox"
import axios from "axios"

import API from "@/API.js"

export default {
  name: "GeneralMSI",
  components: {
    Layout,
    VueEasyLightbox
  },
  data: function(){
    return {
      ajustes: {
        nombre_empresa: "",
        prefnumcli: "",
        prefijo_telefonico: null,
        id_pais: null,
        donde_pagar: true,
        logo_mi_servicio_internet: false
      },
      moneda: {
        id_moneda: null,
        nombre: "",
        simbolo: "",
        codigo: "",
        activo: true
      },
      monedas: [],
      paises: [],
      bandera_spinner: false,
      pais:{
        id: null,
        nombre: '',
        porefijo_telefonico: null
      },

      imagen_logo: null,
      logo: null,
      visibleImagen: false,

      tk: localStorage.getItem('argusblack.token'),
      API: API,
    }
  },
  created: function(){
    var self = this

    self.cargarIdMoneda()
    self.cargarMonedas()
    self.cargarPaises()
    self.refrescarAjustes()
  },
  watch: {
    id_pais: function(newId, oldId){
      var self = this
      self.cargarPais()
    }
  },
  methods: {
    actualizar() {
      let self = this, ajustes = {}

      ajustes = {
        nombre_empresa:             self.ajustes.nombre_empresa,
        prefnumcli:                 self.ajustes.prefnumcli,
        prefijo_telefonico:         self.pais.prefijo_telefonico,
        id_pais:                    self.ajustes.id_pais,
        donde_pagar:                self.ajustes.donde_pagar
      }

      self.bandera_spinner = true

      let prom1 = SistemaSrv.actualizar(ajustes)
      let prom2 = SistemaSrv.actualizarIdMoneda(self.moneda.id_moneda)

      axios.all([prom1, prom2]).then(response => {
        iu.msg.success('Datos actualizados')
        self.cargarIdMoneda()
        self.refrescarAjustes()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },
    cargarIdMoneda: function () {
      var self = this

      SistemaSrv.idMoneda().then(response => {
        self.moneda.id_moneda = response.data ? parseInt(response.data) : null
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },
    cargarMonedas: function() {
      var self = this

      SistemaSrv.monedasJSON().then(response => {
        self.monedas = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },
    cargarPaises: function() {
      var self = this

      let params = {
        datos_solicitados: ['id','nombre','prefijo_telefonico']
      }

      SistemaSrv.paisesJSON(params).then(response => {
        self.paises = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar los paises'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cargarPais() {
      var self = this

      let pais = self.paises.find(pais => {
        return pais.id == self.id_pais
      })

      if(pais == null) return

      self.pais = pais
    },
    refrescarAjustes() {
      let self = this

      SistemaSrv.ajustes(["nombre_empresa", "prefnumcli", "prefijo_telefonico", "id_pais", "donde_pagar", "logo_mi_servicio_internet" ]).then(response => {
        let ajustes = response.data
        self.ajustes.nombre_empresa = ajustes.nombre_empresa
        self.ajustes.prefnumcli = ajustes.prefnumcli
        self.ajustes.prefijo_telefonico = ajustes.prefijo_telefonico
        self.ajustes.id_pais = ajustes.id_pais
        self.ajustes.donde_pagar = ajustes.donde_pagar == "0" ? false : true

        self.ajustes.logo_mi_servicio_internet = ajustes.logo_mi_servicio_internet == "0" ? false : true

        if(self.ajustes.logo_mi_servicio_internet)
          self.imagen_logo = self.API + '/sistema/mi-servicio-internet/logo?_tk=' + self.tk + '&rnd=' + Math.random()
      })
    },

    actualizarLogo: function() {
      var self = this,
        datos = {
          logo: self.logo,
          eliminar_logo : self.ajustes.logo_mi_servicio_internet
        }

      SistemaSrv.actualizarLogoMSI(datos).then(response => {
        iu.msg.success("Logo guardado con exito")
        self.refrescarAjustes()
      }).catch(error => {
        let mensaje
        try {
          mensaje.error.response.data.message
        } catch (e) {
          mensaje = 'No se puede actualizar el logo'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    seleccionarLogo: function(){
      let self = this

      var inpLogo = document.createElement("input")
      inpLogo.setAttribute("type", "file")
      var reader = new FileReader()

      inpLogo.click()

      reader.onload = function(e) {
        self.imagen_logo = this.result
        self.actualizar()
      }

      inpLogo.addEventListener("change", function() {
        reader.readAsDataURL(inpLogo.files[0])
        self.ajustes.logo_mi_servicio_internet = true
        self.logo = inpLogo.files[0]
        self.actualizarLogo()
      })
    },

    eliminarLogo: function() {
      var self = this

      SistemaSrv.eliminarLogoMSI().then(response => {
        iu.msg.success("Logo eliminado con exito")
        self.ajustes.logo_mi_servicio_internet = false
        self.imagen_logo = null
        self.logo = null
        self.refrescarAjustes()
      }).catch(error => {
        let mensaje
        try {
          mensaje.error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo eliminar el logo'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>
<style scoped>
.btnEliminarLogo {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 100;
}
.marcoLogo {
  position: relative;
}
</style>